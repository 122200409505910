import React from "react"
import _ from "lodash"
import { Button, Container, List, Header, Icon, Breadcrumb } from "semantic-ui-react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SelfCheckButton from "../components/SelfCheckButton"

const ConceptsPage = (args) => {
  const unorderedConcepts = args.data.concepts.nodes;
  const concepts = _.sortBy(unorderedConcepts, c => c.parent.name);
  const totalCount = (concept) => {
    const c = _.find(
      args.data.misconceptionConcepts.group,
      ['fieldValue', concept.parent.name]);
    return c ? c.totalCount : 0;
  };
  return <Layout>
    <SEO title="Concepts" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Concepts</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="lightbulb" />
        <Header.Content>
          Concepts
          <Header.Subheader>Find Misconceptions by Concept</Header.Subheader>
        </Header.Content>
        <Button
          as={Link}
          to="/conceptMap"
          primary={true}
          icon
          labelPosition='right'
        >
          <Icon name='sitemap' />
          Explore on a map
        </Button>
      </Header>
      <List selection verticalAlign="middle">
        {concepts.map(concept => (
          <List.Item
            as={Link}
            to={`/concepts/${_.kebabCase(concept.parent.name)}/`}
            key={concept.parent.name}
          >
            <List.Icon name="lightbulb" verticalAlign="middle" />
            <List.Content>
              <List.Content floated='right'>
                <SelfCheckButton concepts={[concept.parent.name]}/>
              </List.Content>
              <List.Header>
                {/* color this like a link, without producing a nested a element */}
                <span style={{ color: "#4183c4" }}>{_.startCase(concept.parent.name)}</span>
                <span style={{ color: "#c0c0c0", fontWeight: 1 }}> &mdash; {totalCount(concept)}</span>
              </List.Header>
              {concept.frontmatter.shortDescription}
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Container>
  </Layout>;
}

export default ConceptsPage

export const pageQuery = graphql`
  query {
    concepts: allMdx(
      filter: {fileAbsolutePath: {glob: "**/src/data/concepts/*.md"}},
    ) {
      nodes {
        frontmatter {
          shortDescription
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
    misconceptionConcepts: allMdx {
      group(field: frontmatter___concepts) {
        fieldValue
        totalCount
      }
    }
  }
`
